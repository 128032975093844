(function buttonlinkScript($, Drupal, once) {
  Drupal.behaviors.pl_accordion = {
    attach(context) {
      $(once('accordionOpenClose', '.accordion__trigger', context)).each(function(){
        $(this).removeAttr('hidden');
        $(this).on('click', function () {
          let expanded = $(this).attr('aria-expanded') === 'true' || false;
          $(this).toggleClass('active');
          $(this).next('.accordion__response').slideToggle(200);
          $(this).parents('.faq-item').toggleClass('active');
          $(this).attr('aria-expanded', !expanded);
        })
      })

    },
  };
}(jQuery, Drupal, once));
